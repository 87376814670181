// Written by: FIT3162 CS Team 1
// Last modified: 1/11/23
// Title: Render image display element

import React from "react";
import L from "leaflet";
import { AttributionControl, ImageOverlay, MapContainer } from "react-leaflet";

import { INFO_PAGE, PRIVACY_POLICY_PAGE } from "App";
import { ImageRender } from "#libs/types";

import "leaflet/dist/leaflet.css";
import EduardLogo from "#assets/logo.png";
import "#styles/components/MapDisplay";


/**
 * Zoomable image display of render using Leaflet map.
 * @param image Image src url and hash time. 
 */
function ImageZoom({ image }: { image: ImageRender }) {
  const PADDING = 0.5;

  const [bounds, setBounds] = React.useState<L.LatLngBounds>(new L.LatLngBounds([[-90, -180], [90, 180]]));
  const imgObj = new Image();
  imgObj.src = image.imageSrc;

  React.useEffect(() => {
    const aspect = imgObj.width / imgObj.height || 1;
    setBounds(new L.LatLngBounds([[-90, -180 * aspect], [90, 180 * aspect]]));
  }, [imgObj.width, imgObj.height]);

  return (
    <MapContainer
      id="map"
      className="image-zoom-container"
      center={[0, 0]}
      zoom={1}
      minZoom={0}
      maxZoom={3}
      maxBounds={bounds.pad(PADDING)}
      maxBoundsViscosity={0.5}
      attributionControl={false}
    >
      <ImageOverlay
        key={image.imageHash}
        url={image.imageSrc}
        bounds={bounds}
        errorOverlayUrl={EduardLogo}
        attribution={`&copy; 2024 Monash University | <a href=${PRIVACY_POLICY_PAGE}>Privacy Policy</a> | <a href=${INFO_PAGE}>Contact</a>`}
      />
      {/* <AttributionControl position="bottomleft" prefix={false} /> */}
    </MapContainer>
  );
}


/**
 * Map display providing either a dropzone or a zoomable image.
 * @param renderUrl url of image to display
 */
function MapDisplay({ renderUrl }: { renderUrl: ImageRender }) {
  return <>
    <ImageZoom image={renderUrl} />
  </>
}

export default MapDisplay;
