// Written by: FIT3162 CS Team 1
// Last modified: 1/11/23
// Title: Back-end request functions

import axios from "axios";
import { Stripe, loadStripe } from "@stripe/stripe-js";
import { CreditsRatio, ProcessingSettings } from "#libs/types";

const api = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL,  // NOTE: Combines URLS if not absolute URL: begins with "<scheme>://"" or "//" (protocol-relative url)
  headers: {                                                             // See: https://stackoverflow.com/a/66038602
    'Accept': 'application/json',
    'Content-Type': 'application/json; charset=UTF-8',
  }
});

/**
 * Starts a render request on the back-end
 * @returns Request unique ID string
 */
async function startRequest() : Promise<string>
{
    try {
        const { data } = await api.post('/api/start_request', {});
        return data.requestId;
    }
    catch (error) {
      if (axios.isAxiosError(error)) {
        console.error('error message: ', error.message);
        return error.message;
      } else {
        console.error('unexpected error: ', error);
        return '';
      }
    }
}

/**
 * Cancel a render request
 * @param requestId Request unique ID string
 * @returns Cancel success response string
 */
async function cancelRequest(requestId: string) : Promise<string>
{
    try {
        console.log("Trying to cancel request:" + requestId)
        const { data } = await api.post('/api/cancel_request', {	
          data: {
            requestId: requestId
          }
      });
        return data;
    }
    catch (error) {
      if (axios.isAxiosError(error)) {
        console.error('error message: ', error.message);
        return error.message;
      } else {
        console.error('unexpected error: ', error);
              return '';
      }
    }
}

/**
 * Get render progress from the back-end
 * @param requestId Request unique ID string
 * @returns Progress percentage integer 0-100
 */
async function getProgress(requestId: string) : Promise<number>
{
  try {
    const { data } = await api.post('/api/progress', {	
      data: {
        requestId: requestId
      }
    });
    return data.progress;
  }
  catch (error) {
    if (axios.isAxiosError(error)) {
      console.error('error message: ', error.message);
      return 100
    } else {
      console.error('unexpected error: ', error);
      return 100;
    }
  }
}

/**
 * Get a user's API key from database
 * @param userAuthKey User auth key string
 * @returns User API key string
 */
async function getUserApiKey(userAuthKey: string) : Promise<string>
{
  try {
    const { data } = await api.post('/api/get_api_key', {	
      data: {
        authKey: userAuthKey
      }
    });
    return data.apiKey;
  }
  catch (error) {
    if (axios.isAxiosError(error)) {
      console.error('error message: ', error.message);
    } 

    // Try checking session storage for api key
    const sessionApiKey = sessionStorage.getItem("SESSION_API_KEY");
    return sessionApiKey || "";
  }
}

/**
 * Set a user's API key in the database
 * @param userAuthKey User auth key string
 * @param userApiKey New user API key string
 * @returns Success boolean
 */
async function setUserApiKey(userAuthKey: string, userApiKey: string) : Promise<boolean>
{
  try {
    const { data } = await api.post('/api/set_api_key', {	
      data: {
        authKey: userAuthKey,
        apiKey: userApiKey
      }
    });

    if (data.success) {
      return true;
    } else {
      return false;
    }
    
  }
  catch (error) {
    if (axios.isAxiosError(error)) {
      console.error('error message: ', error.message);
      return false;
    } else {
      console.error('unexpected error: ', error);
      return false;
    }
  }
}

/**
 * Delete a user account in the database
 * @param userAuthKey User auth key string
 * @returns Success boolean
 */
async function deleteAccount(userAuthKey: string) : Promise<boolean>
{
  try {
    const { data } = await api.post('/api/delete_account', {	
      data: {
        authKey: userAuthKey
      }
    });

    if (data.success) {
      return true;
    } else {
      return false;
    }
    
  }
  catch (error) {
    if (axios.isAxiosError(error)) {
      console.error('error message: ', error.message);
      return false;
    } else {
      console.error('unexpected error: ', error);
      return false;
    }
  }
}

/**
 * Get user credit balance from database
 * @param userAuthKey User auth key
 * @returns User credit balance integer
 */
async function getUserCredits(userAuthKey: string) : Promise<number>
{
  try {
    const { data } = await api.post('/api/get_credits', {	
      data: {
        authKey: userAuthKey
      }
    });

    return data.credits;
  }
  catch (error) {
    if (axios.isAxiosError(error)) {
      console.error('error message: ', error.message);
      return -1;
    } else {
      console.error('unexpected error: ', error);
      return -1;
    }
  }
}

/**
 * Get amount of projects a user has
 * @param userAuthKey User auth key string
 * @returns Integer number of projects
 */
async function getUserProjectAmount(userAuthKey: string) : Promise<number>
{
  try {
    const { data } = await api.post('/api/get_project_amount', {	
      data: {
        authKey: userAuthKey
      }
    });
    return data.numProjects;
  }
  catch (error) {
    if (axios.isAxiosError(error)) {
      console.error('error message: ', error.message);
      return -1;
    } else {
      console.error('unexpected error: ', error);
      return -1;
    }
  }
}

/**
 * Decrement a user balance by 1
 * @param userAuthKey User auth key string
 * @returns Success boolean
 */
async function spendUserCredit(userAuthKey: string) : Promise<boolean>
{
  try {
    const { data } = await api.post('/api/spend_credit', {	
      data: {
        authKey: userAuthKey
      }
    });

    if (data.success) {
      return true;
    } else {
      return false;
    }
    
  }
  catch (error) {
    if (axios.isAxiosError(error)) {
      console.error('error message: ', error.message);
      return false;
    } else {
      console.error('unexpected error: ', error);
      return false;
    }
  }
}

/**
 * Get a list of user map details
 * @param authKey User auth key string
 * @returns List of all maps created by user
 */
async function getUserMaps(authKey: string) : Promise<any>
{
  try {
    const { data } = await api.post('/api/get_all_user_maps', {	
      data: {
        authKey: authKey
      }
    });

    return data
    
  }
  catch (error) {
    if (axios.isAxiosError(error)) {
      console.error('error message: ', error.message);
      return '';
    } else {
      console.error('unexpected error: ', error);
      return '';
    }
  }
}

/**
 * Get all data relating to a map
 * @param mapId Unique map ID string
 * @returns Map data object
 */
async function getMapData(mapId: string) : Promise<any>
{
  try {
    const { data } = await api.post('/api/get_map_data', {	
      data: {
        mapId: mapId
      }
    });

    return data
    
  }
  catch (error) {
    if (axios.isAxiosError(error)) {
      console.error('error message: ', error.message);
      return '';
    } else {
      console.error('unexpected error: ', error);
      return '';
    }
  }
}

/**
 * Update a map name in the database
 * @param mapId Unique map ID string
 * @param mapName New map name string
 * @returns Success boolean
 */
async function setShadingNameInDB(mapId: string, mapName: string) : Promise<boolean>
{
  try {
    const { data } = await api.post('/api/update_map_name', {	
      data: {
        mapId: mapId,
        mapName: mapName
      }
    });

    if (data.success) {
      return true;
    } else {
      return false;
    }
    
  }
  catch (error) {
    if (axios.isAxiosError(error)) {
      console.error('error message: ', error.message);
      return false;
    } else {
      console.error('unexpected error: ', error);
      return false;
    }
  }
}

/**
 * Delete a map in the database
 * @param mapId Unique map ID string
 * @returns Success boolean
 */
async function deleteMap(mapId: string) : Promise<boolean>
{
  try {
    const { data } = await api.post('/api/delete_map', {	
      data: {
        mapId: mapId
      }
    });

    if (data.success) {
      return true;
    } else {
      return false;
    }
    
  }
  catch (error) {
    if (axios.isAxiosError(error)) {
      console.error('error message: ', error.message);
      return false;
    } else {
      console.error('unexpected error: ', error);
      return false;
    }
  }
}


/**
 * Fetch elevation data using the back-end server
 * @param apiKey User API key string
 * @param bounds Geographical selection bounds object
 * @param elevationModel Selected elevation model name
 * @param authKey User auth key string
 * @param projection Selected projection name
 * @returns Raw elevation data from OpenTopography call
 */
async function fetchElevationData(
    apiKey: string,
    bounds: object | undefined,
    elevationModel: string,
    authKey: string,
    projection: string
  ) : 
      Promise<any>
  {
      try {
          const { data, status } = await api.post('/api/fetch', {	
              data: {
                  elevationData: { apiKey: apiKey, bounds: bounds, elevationModel: elevationModel }, authKey: authKey, projection: projection
              }
          });
          // Debug msgs
          console.log(status);
          
          return data;
      }
      catch (error) {
          if (axios.isAxiosError(error)) {
        console.error('error message: ', error.message);
        return "error";
      } else {
        console.error('unexpected error: ', error);
        return "error";
      }
      }
  }

/**
 * Render a shaded relief image on the back-end server using cached data and current settings
 * @param cachedData Cached data string
 * @param settings Render settings object
 * @param isDemo Boolean which is true if a demo
 * @param requestId Unique render request code
 * @param projection Selected projection name
 * @param authKey User auth key string
 * @param mapId Unique map ID string
 * @returns Processed shaded relief image based on cached data and settings
 */
async function processCachedTiff(
  cachedData: string,
  settings: ProcessingSettings,
  isDemo: Boolean,
  requestId: string,
  projection: string,
  authKey: string,
  mapId: string
) : 
    Promise<any>
{
  try {
      const { data, status } = await api.post('/api/process', {	
          data: {
              elevationData: cachedData, settings: settings, isDemo: isDemo, requestId: requestId, projection: projection, authKey: authKey, mapId: mapId
          }
      });
      
      return data;
  }
  catch (error) {
    if (axios.isAxiosError(error)) {
      console.error('error message: ', error.message);
      return "error";
    } else {
      console.error('unexpected error: ', error);
      return "error";
    }
  }
}

let stripePromise: Promise<Stripe | null> | null = null;
/**
 * Load Stripe connection with key
 * @returns  Stripe promise
 */
const getStripe = () => {
  if (!stripePromise && process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY) {
    stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);
  }
  return stripePromise;
};

/**
 * Gets unit amount in cents
 * Update: Default currency to USD (18/9/2024) 
 * 
 * @param toCurrency Currency identifier string
 * @returns Number of cents
 */
function getUnitAmount(toCurrency: string): number {
  return 100;
  // if (toCurrency === "usd") {
  //   return 64; // Convert to USD and round to cents
  // } else if (toCurrency === "eur") {
  //   return 60; // Convert to EUR and round to cents
  // }
  // else {
  //   return 100;
  // }
}

/***
 * Redirect and handle stripe purchase
 */
async function handleStripePurchase(creditAmount: number, selectedOption: string, authKey: any) {
  const stripe = await getStripe();

  try {
    // Send the purchase checkout details to the back-end
    const response = await api.post(`/api/payment`, {
      quantity: creditAmount, 
      currency: selectedOption, 
      unitAmount: getUnitAmount(selectedOption), 
      authKey: authKey 
    });

    // Display error if purchase failed
    if (response.status !== 200) {
      throw new Error('Network response was not ok ' + response.statusText);
    }

    // Redirect to the Stripe checkout
    const { id: sessionId } = await response.data;
    if (stripe) {
      const { error } = await stripe.redirectToCheckout({ sessionId });
    }
    else { console.error("error"); }
  } catch (error) {
    console.error('Error:', error);
  }
}

async function getPurchaseRatio(): Promise<CreditsRatio> {
    const response = await api.get('/api/prices');
    
    // Display error if purchase failed
    if (response.status !== 200) {
      throw new Error('Unable to fetch prices from server ' + response.statusText);
    }
    return { 
      credits: response.data['credits'], 
      cost: response.data['cost'], 
      currency: response.data['currency'] 
    };
}


// function post_project(geoTiff: any, settings: Processing) {}
export { startRequest, cancelRequest, processCachedTiff, fetchElevationData, getProgress, getUserApiKey, setUserApiKey, getUserCredits, spendUserCredit, deleteAccount, getUserMaps, getMapData, deleteMap, getUserProjectAmount, setShadingNameInDB, handleStripePurchase, getPurchaseRatio};